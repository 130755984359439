import React, { useState, useEffect, useCallback } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from './Header';
import { supabase } from '../supabaseClient';
import axios from 'axios';
import Overall from './OverallPortfolio';
import ReactApexChart from "react-apexcharts";
import PortfolioNew from './NewPortfolio/PrtfoiloCharts';
import PortfolioMap from './PortfolioMap';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  cardColor: '#fff'
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
  cardColor: "rgb(55 65 81)"
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 100px;
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
`;

const Card = styled.div`
  width:49%;
  height:500px;
  display:flex;
  position:relative;
  // flex:2;
  background-color: ${({ theme }) => theme.cardColor};
  // border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top:10px;

  &:hover {
    // transform: translateY(-5px);
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  }
`;


const Title = styled.h2`
  color: ${({ theme }) => theme.primary};
  margin-bottom: 15px;
  font-weight:bold;
  font-size:1.5em;
`;

const Table = styled.table`
  // width: 100%;  
  border-collapse: collapse;
  margin-top: 10px;
  
  margin-right: 10px;
  th, td {
    padding: 20px;
    border: 1px solid gray;
    text-align: left;
    width:100px;    
    // height:75px;
  }

  th {
    background-color: rgb(55 65 81);
    color: #fff;
  }

  td {
    background-color: ${({ theme }) => theme.cardColor};
    color: ${({ theme }) => theme.color};
  }

  tbody {
    display: block;
    max-height:400px;
    overflow-y: auto;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;    
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1; /* Ensure header stays above the content */
  }
`;
const TableContainer = styled.div`
  // width: 100%;
  position:relative;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Enable horizontal scrolling */
`;

const Table1 = styled.table`
  // width: 100%;  
  border-collapse: collapse;
  margin-top: 10px;
  margin-right: 10px;

  th, td {
    padding: 20px;
    border: 1px solid gray;
    text-align: left;
    width:100px;    
    height:100px;
  }

  th {
    background-color: rgb(55 65 81);
    color: #fff;
  }

  td {
    background-color: ${({ theme }) => theme.cardColor};
    color: ${({ theme }) => theme.color};
  }

  tbody {
    display: block;
    // min-height: 250px; /* Adjust based on the desired height */
    overflow-y: auto;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;    
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1; /* Ensure header stays above the content */
  }
`;

const Table2 = styled.table`
  // width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  // margin-right: 10px;
  // height: 300px;

  th, td {
    padding: 10px;
    border: 1px solid gray;
    text-align: left;
  }

  th {
    background-color: rgb(55 65 81);
    color: #fff;
  }

  td {
    background-color: ${({ theme }) => theme.cardColor};
    color: ${({ theme }) => theme.color};
  }

  tbody {
    display: block;
    max-height: 450px; /* Adjust based on the desired height */
    overflow-y: auto;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    position: sticky;
    top: 0;
  }
`;



const StatisticsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap:20px;
// backgrouund: #fff;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;TableContainer
  border: none;
  cursor: pointer;
  border-radius: 5px;
  float:right;

  &:hover {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 30px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  text-align: left;
  transition: all 0.3s ease;
`;

const ModalTitle = styled.h3`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.primary};
  font-size: 24px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: ${({ theme }) => theme.color};
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.secondary};
    outline: none;
  }
`;

const ModalButton = styled.button`
  padding: 12px 20px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.secondary};
  }

  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PortfolioPage = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });

  const [portfolioData, setPortfolioData] = useState({
    symbol: '',
    shares: '',
    weight: '',
    Purchase_Value: '',
    currency: 'USD',
  });

  const [portfolioItems, setPortfolioItems] = useState([]);
  const [pfId, setPfId] = useState(1);
  const [pfIds, setPfIds] = useState([1]);
  const [pfNames, setPfNames] = useState([]);
  
  const [totalPortfolioValue, setTotalPortfolioValue] = useState(0);
  const [error, setError] = useState(null);
  const [clientUserId, setClientUserId] = useState(localStorage.getItem('client_id'));
  const [stockPrices, setStockPrices] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [portfolioStatisticsData, setPortfolioStatisticsData] = useState({});
  const [corelationData, setCorelationData] = useState([]);
  const [isAddNewPortfolio, setIsAddNewPortfolio] = useState(false);
  const [monteCarloChartData, setMonteCarloChartData] = useState([]);
  const [monteCarloTableData, setMonteCarloTableData] = useState([]);
  const [monteCarloTableDataPartThree, setMonteCarloTableDataPartThree] = useState([]);
  const [monteCarloTableDataId, setMonteCarloTableDataId] = useState([]);



  const handlePortfolioToggle = () => {
    setIsAddNewPortfolio(!isAddNewPortfolio);
  }
  const fetchStockPrices = useCallback(async (symbols) => {
    const prices = {};
    for (const symbol of symbols) {
      try {
        const response = await axios.get(
          `https://financialmodelingprep.com/api/v3/quote-short/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e`
        );
        prices[symbol] = response?.[0]?.price || 0;
      } catch (error) {
        console.error(`Error fetching price for ${symbol}:`, error);
        prices[symbol] = 0;
      }
    }
    setStockPrices(prices);
  }, []);

  const fetchPortfolioIds = async () => {
    const { data, error } = await supabase
      .from('PortfolioIdentity')
      .select('Portfolio_ID,Portfolio_Name')
      .eq('Client_ID', clientUserId)

    if (error) {
      throw new Error(`Error fetching PortfolioIdentity: ${error}`);
    }

    return data;

  }

  const monte_carlo_simulations_chart_data = async () => {

    const { data: maxDateData, error: maxDateError } = await supabase
      .from('MonteCarloSimulations_PartOne')
      .select('DateTime')
      .eq('Client_ID', clientUserId)
      .eq('Portfolio_ID', pfId)
      .order('DateTime', { ascending: false })
      .limit(1)
      .single(); // Get the latest date

    if (maxDateError) {
      throw new Error(`Error fetching max date: ${maxDateError.message}`);
    }

    const maxDate = maxDateData?.DateTime;

    if (!maxDate) {
      throw new Error('No data found for the given Client_ID and Portfolio_ID.');
    }

    const { data, error } = await supabase
      .from('MonteCarloSimulations_PartOne')
      .select('MonteCarloSimulations_ID, Return, StandardDeviation')
      .eq('Client_ID', clientUserId)
      .eq('Portfolio_ID', pfId)
      .eq('DateTime', maxDate)

    if (error) {
      console.error('Error:', error);
    } else {
      console.log('Result:', data);
      return data;
    }
    return data;

  }

  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        // Fetch portfolio composition
        fetchPortfolioStatistics(clientUserId, pfId).then((data) => {          
          if (data?.length)
            setPortfolioStatisticsData(data[0]);
          else
            setPortfolioStatisticsData({});
        });
        fetchCovarianceCorrelation(clientUserId, pfId).then((data) => {          
          setCorelationData(data);
        });
        fetchPortfolioIds().then((data) => {
          setPfIds(data.map(item => item.Portfolio_ID));
          setPfNames(data.map(item => item.Portfolio_Name));
        })
        console.log(pfNames,'names');
        
        monte_carlo_simulations_chart_data().then((data) => {
          setMonteCarloChartData(data);
        })
        const { data, error } = await supabase
          .from('PortfolioComposition')
          .select('*')
          .eq('Client_ID', clientUserId);

        if (error) {
          setError("Error fetching portfolio from database: " + error.message);
        } else {
          const transformedData = data.reduce((result, row) => {
            const { Portfolio_ID, Symbol, Share, Purchase_Value, Weight } = row;
            if (!result[Portfolio_ID]) result[Portfolio_ID] = [];
            result[Portfolio_ID].push({ Symbol, Share, Purchase_Value, Weight });
            return result;
          }, {});
          // setPfIds(Object.keys(transformedData));

          setPortfolioItems(transformedData);
        }
      } catch (err) {
        setError("An unexpected error occurred: " + err.message);
      }
    };

    fetchPortfolioData();
  }, [clientUserId, pfId]);

  // Calculate portfolio value
  useEffect(() => {
    const calculatePortfolioValue = () => {
      if (portfolioItems[pfId]?.length > 0) {
        const symbols = portfolioItems[pfId].map(item => item.Symbol);
        fetchStockPrices(symbols);
      }
    };

    calculatePortfolioValue();
  }, [portfolioItems, pfId, fetchStockPrices]);

  // Update total portfolio value
  useEffect(() => {
    if (Object.keys(stockPrices).length > 0 && portfolioItems[pfId]) {
      const totalValue = portfolioItems[pfId].reduce((total, item) => {
        if (item.Share > 0) {
          const currentPrice = stockPrices[item.Symbol] || 0;
          total += item.Share * currentPrice;
        }
        return total;
      }, 0);
      setTotalPortfolioValue(totalValue);
    }
  }, [stockPrices, portfolioItems, pfId]);

  const handleModalToggle = () => setIsModalOpen(!isModalOpen);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPortfolioData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Close the modal after submission
    setIsModalOpen(false);

    setPortfolioItems((prevItems) => {
      const safePrevItems = Array.isArray(prevItems) ? prevItems : [];
      return [...safePrevItems, { id: pfId, data: portfolioData }];
    });

    try {
      const { error } = await supabase
        .from('PortfolioComposition')
        .insert([
          {
            Client_ID: parseInt(localStorage.getItem('client_id')),
            Symbol: portfolioData.symbol,
            Share: parseFloat(portfolioData.shares),
            Weight: portfolioData.weight || undefined,
            Purchase_Value: parseFloat(portfolioData.Purchase_Value),
            Portfolio_ID: parseInt(pfId),
          },
        ]);

      if (error) {
        setError("Error adding portfolio to database: " + error.message);
      } else {
        setError(null);
        console.log("Portfolio added to database.");
      }
    } catch (err) {
      setError("An unexpected error occurred: " + err.message);
    }

    setPortfolioData({
      symbol: '',
      shares: '',
      weight: '',
      Purchase_Value: '',
    });
  };

  const fetchPortfolioStatistics = async (clientId, portfolioId) => {
    try {
      // Fetch the latest date for the portfolio statistics
      const { data: maxDateData, error: maxDateError } = await supabase
        .from('PortfolioStatistics')
        .select('date')
        .eq('Client_ID', clientId)
        .eq('Portfolio_ID', portfolioId)
        .order('date', { ascending: false })
        .limit(1)
        .single(); // Get the latest date

      if (maxDateError) {
        throw new Error(`Error fetching max date: ${maxDateError.message}`);
      }

      const maxDate = maxDateData?.date;

      if (!maxDate) {
        throw new Error('No data found for the given Client_ID and Portfolio_ID.');
      }

      // Fetch the portfolio statistics for the latest date
      const { data, error } = await supabase
        .from('PortfolioStatistics')
        .select(`
        "StandardDeviation5", "StandardDeviation3", "StandardDeviation1",
        "Variance5", "Variance3", "Variance1",
        "Beta5", "Beta3", "Beta1",
        "VaR_(95%)5", "VaR_(95%)3", "VaR_(95%)1",
        "R_squared5", "R_squared3", "R_squared1",
        "SharpeRatio5", "SharpeRatio3", "SharpeRatio1",
        "SortinoRatio5", "SortinoRatio3", "SortinoRatio1",
        "MaxDrawdown5", "MaxDrawdown3", "MaxDrawdown1",
        "TreynorRatio5", "TreynorRatio3", "TreynorRatio1",
        "CVaR_(95%)5", "CVaR_(95%)3", "CVaR_(95%)1",
        "Skewness5", "Skewness3", "Skewness1",
        "Kurtosis5", "Kurtosis3", "Kurtosis1"
      `)
        .eq('Client_ID', clientId)
        .eq('Portfolio_ID', portfolioId)
        .eq('date', maxDate);
      if (error) {
        throw new Error(`Error fetching portfolio statistics: ${error.message}`);
      }

      return data;
    } catch (err) {
      console.error(err.message);
      return null;
    }
  }

  const fetchCovarianceCorrelation = async (clientId, portfolioId) => {
    // Step 1: Fetch the latest date for the specific Client_ID and Portfolio_ID
    const { data: latestDateData, error: dateError } = await supabase
      .from('PortfolioCovarianceCorrelation')
      .select('date')
      .eq('Client_ID', clientId)
      .eq('Portfolio_ID', portfolioId)
      .order('date', { ascending: false })
      .limit(1); // Get only the latest record

    if (dateError) {
      console.error('Error fetching the latest date:', dateError);
      return;
    }

    const latestDate = latestDateData?.[0]?.date;

    if (!latestDate) {
      console.log('No data found for the given Client_ID and Portfolio_ID.');
      return;
    }

    // Step 2: Fetch the covariance/correlation data for the latest date
    const { data, error } = await supabase
      .from('PortfolioCovarianceCorrelation')
      .select('Security1, Security2, Covariance5, Covariance3, Covariance1, Correlation5, Correlation3, Correlation1')
      .eq('Client_ID', clientId)
      .eq('Portfolio_ID', portfolioId)
      .eq('date', latestDate) // Use the latest date from the previous step

    if (error) {
      console.error('Error fetching covariance/correlation data:', error);
    } else {
      return data;
    }
  };

  const fetchMonteCarloSumulationsTableData = async (id) => {
    const { data: maxDateData, error: maxDateError } = await supabase
      .from('MonteCarloSimulations_PartTwo')
      .select('DateTime')
      .eq('Client_ID', clientUserId)
      .eq('Portfolio_ID', pfId)
      .eq('MonteCarloSimulations_ID', id)
      .order('DateTime', { ascending: false })
      .limit(1)
      .single(); // Get the latest date

    if (maxDateError) {
      throw new Error(`Error fetching max date: ${maxDateError.message}`);
    }

    const maxDate = maxDateData?.DateTime;

    if (!maxDate) {
      throw new Error('No data found for the given Client_ID and Portfolio_ID.');
    }

    const { data, error } = await supabase
      .from('MonteCarloSimulations_PartTwo')
      .select('Symbol, CurrentWeight, ProposedWeight')
      .eq('Client_ID', clientUserId)
      .eq('Portfolio_ID', pfId)
      .eq('MonteCarloSimulations_ID', id)
      .eq('DateTime', maxDate)

    if (error) {
      console.error('Error:', error);
    } else {
      console.log('Result:', data);
      return data;
    }
    return data;
  }

  const fetchMonteCarloStatisticsPartThree = async (id) => {
    // Fetch the latest date
    const { data: maxDateData, error: maxDateError } = await supabase
      .from('MonteCarloSimulations_PartThree_PortfolioStatistics')
      .select('DateTime')
      .eq('Client_ID', clientUserId)
      .eq('Portfolio_ID', pfId)
      .eq('MonteCarloSimulations_ID', id)
      .order('DateTime', { ascending: false })
      .limit(1)
      .single(); // Get the latest date

    if (maxDateError) {
      throw new Error(`Error fetching max date: ${maxDateError.message}`);
    }

    const maxDate = maxDateData?.DateTime;

    if (!maxDate) {
      throw new Error('No data found for the given Client_ID, Portfolio_ID, or MonteCarloSimulations_ID.');
    }

    // Fetch Monte Carlo statistics for the latest date
    const { data, error } = await supabase
      .from('MonteCarloSimulations_PartThree_PortfolioStatistics')
      .select(`
        "StandardDeviation5", "StandardDeviation3", "StandardDeviation1",
       "Variance5", "Variance3", "Variance1",
       "Beta5", "Beta3", "Beta1",
       "VaR_(95%)5", "VaR_(95%)3", "VaR_(95%)1",
       "R_squared5", "R_squared3", "R_squared1",
       "SharpeRatio5", "SharpeRatio3", "SharpeRatio1",
       "SortinoRatio5", "SortinoRatio3", "SortinoRatio1",
       "MaxDrawdown5", "MaxDrawdown3", "MaxDrawdown1",
       "TreynorRatio5", "TreynorRatio3", "TreynorRatio1",
       "CVaR_(95%)5", "CVaR_(95%)3", "CVaR_(95%)1",
       "Skewness5", "Skewness3", "Skewness1",
       "Kurtosis5", "Kurtosis3", "Kurtosis1"
      `)
      .eq('Client_ID', clientUserId)
      .eq('Portfolio_ID', pfId)
      .eq('MonteCarloSimulations_ID', id)
      .eq('DateTime', maxDate);

    if (error) {
      console.error('Error fetching statistics:', error.message);
      return null;
    }

    return data;
  };


  const seriesData = monteCarloChartData.map((point) => ({
    x: point.StandardDeviation,
    y: point.Return,
    MonteCarloSimulations_ID: point.MonteCarloSimulations_ID, // Store ID for click handling
  }));


  // Chart options
  const options = {
    chart: {
      type: "scatter",
      height: 350,
      zoom: {
        enabled: true,
        type: "xy",
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          const dataIndex = config.dataPointIndex;
          const selectedPoint = seriesData[dataIndex];
          if (selectedPoint) {
            fetchMonteCarloSumulationsTableData(selectedPoint.MonteCarloSimulations_ID).then((data) => {
              setMonteCarloTableData(data);
              setMonteCarloTableDataId(selectedPoint.MonteCarloSimulations_ID);
            });
            fetchMonteCarloStatisticsPartThree(selectedPoint.MonteCarloSimulations_ID).then((data) => {
              setMonteCarloTableDataPartThree(data);
            })
          }
        },
      },
    },
    xaxis: {
      title: {
        text: "Expected Volatility",
      },
      tickAmount: 10,
      labels: {
        formatter: (value) => value.toFixed(4), // Format x-axis labels to 4 decimal places
      },
    },
    yaxis: {
      title: {
        text: "Expected Return",
      },
      labels: {
        formatter: (value) => value.toFixed(4), // Format y-axis labels to 4 decimal places
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ seriesIndex, dataPointIndex }) {
        const point = seriesData[dataPointIndex];
        return `
          <div style="padding: 5px; text-align: center;color:black">
            <strong>MonteCarloSimulations_ID:</strong> ${point.MonteCarloSimulations_ID}<br />
            <strong>Volatility:</strong> ${point.x.toFixed(4)}<br />
            <strong>Return:</strong> ${point.y.toFixed(4)}
          </div>`;
      },
    },
  };

  const handleLayoutModeChange = (newMode) => {    
    
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  return (
    <ThemeProvider theme={theme}>

      <GlobalStyle />
      <Container>


      <Header 
        layoutModeType={theme === lightTheme ? 'light' : 'dark'}
        onChangeLayoutMode={handleLayoutModeChange}/>
        {isAddNewPortfolio &&
          <Overall isAddNewPortfolio={isAddNewPortfolio} setIsAddNewPortfolio={handlePortfolioToggle} pfCount={pfIds.length}>
          </Overall>}

        {!isAddNewPortfolio &&
          <MainContent>
            <div style={{ display: "ruby" }}>
              <Title>Select Portfolio :</Title>
              <select
                id="portfolio"
                value={pfId}
                onChange={(e) => setPfId(e.target.value)}
                style={{ marginLeft: "10px", padding:'10px'}}
              >
                {pfIds.map((portfolioId, index) => (
                  <option key={index} value={portfolioId}>
                    {pfNames[index]  && pfNames[index]}
                  </option>
                ))}
              </select>
              {portfolioItems[pfId] &&
                <Title style={{ fontWeight: 'bold', marginLeft: "10px" }}>Total Portfolio Value: ${totalPortfolioValue !== null ? totalPortfolioValue.toLocaleString() : 'Loading...'}<br /></Title>
              }
            </div>
            <br />


            {/* <Button onClick={handleModalToggle} style={{ marginLeft: "10px" }}>Add New Shares</Button>             */}
            {/* <Button onClick={handlePortfolioToggle}>Add New Portfolio</Button><br /><br /> */}
            {/* <h2>Total Portfolio Value: ${totalPortfolioValue.toFixed(2)}</h2> */}


            {!portfolioItems[pfId] &&
              <div style={{ color: 'red' }}>No Data Available</div>
            }
          
            <PortfolioNew pfId={pfId} theme1={theme}/>
            <PortfolioMap portfolioId={pfId} />
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {portfolioItems[pfId] &&
              <div>
<StatisticsContainer>
                <Card>
                  <TableContainer>
                    <Title>Portfolio Shares</Title>
                <Table>
                  <thead>
                    <tr>
                      <th>Symbol</th>
                      <th>Shares</th>
                      <th>Weight</th>
                      <th>Purchase Value</th>
                      <th>Total Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {portfolioItems[pfId]?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.Symbol}</td>
                        <td>{item.Share}</td>
                        <td>{item.Weight}</td>
                        <td>{item.Purchase_Value}</td>
                        <td>{(item.Share * stockPrices[item.Symbol]).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                </TableContainer>
                </Card>

                {/* </StatisticsContainer> */}
                {/* <StatisticsContainer> */}
                  {portfolioStatisticsData && (

                    <Card>
                      <TableContainer>
                        <Table1>
                          <Title>Portfolio Statistics</Title>
                          <thead>
                            <tr>
                              <th>Time Period</th>
                              <th>Standard Deviation</th>
                              <th>Variance</th>
                              <th>Beta</th>
                              <th>VaR (95%)</th>
                              <th>R Squared</th>
                              <th>Sharpe Ratio</th>
                              <th>Sortino Ratio</th>
                              <th>Max Drawdown</th>
                              <th>Treynor Ratio</th>
                              <th>CVaR (95%)</th>
                              <th>Skewness</th>
                              <th>Kurtosis</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>5 Years</td>
                              <td>{portfolioStatisticsData.StandardDeviation5}</td>
                              <td>{portfolioStatisticsData.Variance5}</td>
                              <td>{portfolioStatisticsData.Beta5}</td>
                              <td>{portfolioStatisticsData['VaR_(95%)5']}</td>
                              <td>{portfolioStatisticsData.R_squared5}</td>
                              <td>{portfolioStatisticsData.SharpeRatio5}</td>
                              <td>{portfolioStatisticsData.SortinoRatio5}</td>
                              <td>{portfolioStatisticsData.MaxDrawdown5}</td>
                              <td>{portfolioStatisticsData.TreynorRatio5}</td>
                              <td>{portfolioStatisticsData['CVaR_(95%)5']}</td>
                              <td>{portfolioStatisticsData.Skewness5}</td>
                              <td>{portfolioStatisticsData.Kurtosis5}</td>
                            </tr>
                            <tr>
                              <td>3 Years</td>
                              <td>{portfolioStatisticsData.StandardDeviation3}</td>
                              <td>{portfolioStatisticsData.Variance3}</td>
                              <td>{portfolioStatisticsData.Beta3}</td>
                              <td>{portfolioStatisticsData['VaR_(95%)3']}</td>
                              <td>{portfolioStatisticsData.R_squared3}</td>
                              <td>{portfolioStatisticsData.SharpeRatio3}</td>
                              <td>{portfolioStatisticsData.SortinoRatio3}</td>
                              <td>{portfolioStatisticsData.MaxDrawdown3}</td>
                              <td>{portfolioStatisticsData.TreynorRatio3}</td>
                              <td>{portfolioStatisticsData['CVaR_(95%)3']}</td>
                              <td>{portfolioStatisticsData.Skewness3}</td>
                              <td>{portfolioStatisticsData.Kurtosis3}</td>
                            </tr>
                            <tr>
                              <td>1 Year</td>
                              <td>{portfolioStatisticsData.StandardDeviation1}</td>
                              <td>{portfolioStatisticsData.Variance1}</td>
                              <td>{portfolioStatisticsData.Beta1}</td>
                              <td>{portfolioStatisticsData['VaR_(95%)1']}</td>
                              <td>{portfolioStatisticsData.R_squared1}</td>
                              <td>{portfolioStatisticsData.SharpeRatio1}</td>
                              <td>{portfolioStatisticsData.SortinoRatio1}</td>
                              <td>{portfolioStatisticsData.MaxDrawdown1}</td>
                              <td>{portfolioStatisticsData.TreynorRatio1}</td>
                              <td>{portfolioStatisticsData['CVaR_(95%)1']}</td>
                              <td>{portfolioStatisticsData.Skewness1}</td>
                              <td>{portfolioStatisticsData.Kurtosis1}</td>
                            </tr>
                          </tbody>
                        </Table1>
                      </TableContainer>
                    </Card>

                  )}
                  {corelationData &&
                    <Card>
                      <TableContainer>
                        <Title>Portfolio Covariance and Correlation</Title>
                        <Table2>
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th colSpan={3} style={{ textAlign: 'center', backgroundColor: '#9c99ff' }}>Covariance</th> {/* Soft yellow */}
                              <th colSpan={3} style={{ textAlign: 'center', backgroundColor: '#58caea' }}>Correlation</th> {/* Light blue */}
                            </tr>
                            <tr>
                              <th>Security 1</th>
                              <th>Security 2</th>
                              <th style={{ backgroundColor: '#9c99ff' }}>5Y</th>
                              <th style={{ backgroundColor: '#9c99ff' }}>3Y</th>
                              <th style={{ backgroundColor: '#9c99ff' }}>1Y</th>
                              <th style={{ backgroundColor: '#58caea' }}>5Y</th>
                              <th style={{ backgroundColor: '#58caea' }}>3Y</th>
                              <th style={{ backgroundColor: '#58caea' }}>1Y</th>
                            </tr>
                          </thead>
                          <tbody>
                            {corelationData?.map((item, index) => (
                              <tr key={index}>
                                <td>{item.Security1}</td>
                                <td>{item.Security2}</td>
                                <td style={{ backgroundColor: '#9c99ff' }}>{item.Covariance5}</td> {/* Soft yellow for Covariance */}
                                <td style={{ backgroundColor: '#9c99ff' }}>{item.Covariance3}</td>
                                <td style={{ backgroundColor: '#9c99ff' }}>{item.Covariance1}</td>
                                <td style={{ backgroundColor: '#58caea' }}>{item.Correlation5?.toFixed(4)}</td> {/* Light blue for Correlation */}
                                <td style={{ backgroundColor: '#58caea' }}>{item.Correlation3?.toFixed(4)}</td>
                                <td style={{ backgroundColor: '#58caea' }}>{item.Correlation1?.toFixed(4)}</td>
                              </tr>
                            ))}
                            {/* Add more rows as needed */}
                          </tbody>
                        </Table2>
                      </TableContainer>
                    </Card>

                  }
                  {/* </StatisticsContainer> */}

                  {/* <Card> */}
                  {/* <StatisticsContainer> */}
                  <Card>

                    <div style={{ width: "100%", margin: "0 auto" }}>
                      <Title>Portfolio Efficient Frontier</Title>
                      <ReactApexChart
                        options={options}
                        series={[{ name: "Efficient Frontier", data: seriesData }]}
                        type="scatter"
                        height={400}
                      />
                    </div>
                  </Card>
                  {monteCarloTableData.length > 0 &&
                    <Card>

                      
                      <TableContainer>
                      <Title>
                        MonteCarloSimulations ID: &nbsp;&nbsp; 
                        {monteCarloTableDataId}
                      </Title>
                      <Table>
                        <thead>
                          <tr>
                            <th>Symbol</th>
                            <th>Current Weight</th>
                            <th>Proposed Weight</th>
                          </tr>
                        </thead>
                        <tbody>
                          {monteCarloTableData?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.Symbol}</td>
                              <td>{item.CurrentWeight?.toFixed(4)}</td>
                              <td>{item.ProposedWeight?.toFixed(4)}</td>

                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      </TableContainer>

                    </Card>
                  }
                  {/* </StatisticsContainer> */}
                  {monteCarloTableDataPartThree.length > 0 && (
                    <Card>
                      
                      
                      <TableContainer>
                      <Title>
                        MonteCarloSimulations ID: &nbsp;&nbsp; 
                        {monteCarloTableDataId}
                      </Title>
                        <Table1>
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Standard Deviation</th>
                              <th>Variance</th>
                              <th>Beta</th>
                              <th>VaR (95%)</th>
                              <th>R-squared</th>
                              <th>Sharpe Ratio</th>
                              <th>Sortino Ratio</th>
                              <th>Max Drawdown</th>
                              <th>Treynor Ratio</th>
                              <th>CVaR (95%)</th>
                              <th>Skewness</th>
                              <th>Kurtosis</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>5y</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.StandardDeviation5?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.Variance5?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.Beta5?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["VaR_(95%)5"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["R_squared5"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["SharpeRatio5"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["SortinoRatio5"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["MaxDrawdown5"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["TreynorRatio5"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["CVaR_(95%)5"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["Skewness5"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["Kurtosis5"]?.toFixed(4)}</td>
                            </tr>
                            <tr>
                              <td>3y</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.StandardDeviation3?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.Variance3?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.Beta3?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["VaR_(95%)3"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["R_squared3"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["SharpeRatio3"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["SortinoRatio3"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["MaxDrawdown3"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["TreynorRatio3"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["CVaR_(95%)3"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["Skewness3"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["Kurtosis3"]?.toFixed(4)}</td>
                            </tr>
                            <tr>
                              <td>1y</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.StandardDeviation1?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.Variance1?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.Beta1?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["VaR_(95%)1"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["R_squared1"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["SharpeRatio1"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["SortinoRatio1"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["MaxDrawdown1"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["TreynorRatio1"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["CVaR_(95%)1"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["Skewness1"]?.toFixed(4)}</td>
                              <td>{monteCarloTableDataPartThree?.[0]?.["Kurtosis1"]?.toFixed(4)}</td>
                            </tr>
                          </tbody>
                        </Table1>

                      </TableContainer>
                    </Card>


                  )}
                </StatisticsContainer>

                {/* </Card> */}

              </div>
            }
          </MainContent>
        }

        <Modal isOpen={isModalOpen}>
          <ModalContent>
            <ModalTitle>Add New Shares</ModalTitle>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Symbol</Label>
                <Input
                  type="text"
                  name="symbol"
                  value={portfolioData.symbol}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>Shares</Label>
                <Input
                  type="number"
                  name="shares"
                  value={portfolioData.shares}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>Weight</Label>
                <Input
                  type="text"
                  name="weight"
                  value={portfolioData.weight}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>Purchase Value</Label>
                <Input
                  type="number"
                  name="Purchase_Value"
                  value={portfolioData.Purchase_Value}
                  onChange={handleChange}
                />
              </FormGroup>
              <ButtonContainer>
                <ModalButton type="submit">Submit</ModalButton>
                <ModalButton type="button" onClick={handleModalToggle}>Cancel</ModalButton>
              </ButtonContainer>
            </form>
          </ModalContent>
        </Modal>

      </Container>
    </ThemeProvider>
  );
};

export default PortfolioPage;
