import { supabase } from '../../supabaseClient';
import axios from 'axios';

async function clientAndPortfolioComposition(clientIdNumber) {
  /**
   * Fetches portfolio composition data from a Supabase database.
   *
   * Args:
   *   clientIdNumber: The ID of the client.
   *   portfolioIdNumber: The ID of the portfolio.
   *
   * Returns:
   *   An array of objects with 'Symbol' as a string and 'Share' as a float.
   */

  try {        
    // Query the Supabase database
    const { data, error } = await supabase
      .from('PortfolioComposition')
      .select('Portfolio_ID,Symbol, Share')
      .eq('Client_ID', parseInt(clientIdNumber))
      .neq('Share', 0);

      console.log('pfdata',data);
      
    if (error) {
      throw new Error(`Error fetching portfolio composition: ${error.message}`);
    }

    // Map results to ensure correct data types
      const transformedData = data.reduce((result, row) => {
      const { Portfolio_ID, Symbol, Share } = row;

      if (!result[Portfolio_ID]) {
        result[Portfolio_ID] = []; // Initialize as an array if it doesn't exist
      }

      // Add Symbol and Share as an object to the Portfolio_ID key
      result[Portfolio_ID].push({ Symbol, Share });

      return result;
    }, {});

    // const portfolioData = data.map(row => ({
    //   Symbol: String(row.Symbol),
    //   Share: parseFloat(row.Share)
    // }));
console.log(transformedData);

    return transformedData;
  } catch (err) {
    console.error('Error fetching portfolio composition:', err);
    throw err;
  }
}

async function clientAndPortfolioIndex(clientIdNumber, portfolioIdNumber) {
    /**
     * Fetches portfolio composition index from a Supabase database.
     *
     * Args:
     *   clientIdNumber: The ID of the client.
     *   portfolioIdNumber: The ID of the portfolio.
     *
     * Returns:
     *   An array of objects with 'Symbol' as a string.
     */
    let indexData = [];
  
    try {
      // Query the Supabase database to get Portfolio_Benchmark values
      const { data, error } = await supabase
        .from('PortfolioComposition')
        .select('Portfolio_Benchmark')
        .eq('Client_ID', clientIdNumber)
        .eq('Portfolio_ID', portfolioIdNumber)
        .neq('Portfolio_Benchmark', null);
    
      if (error) {
        console.error(`Error fetching portfolio index: ${error.message}`);
      }
    
      // Use a Set to get distinct Portfolio_Benchmark values
      if(data)
       indexData = [...new Set(data.map(row => row.Portfolio_Benchmark))];
      // console.log(uniqueBenchmarks,'bm');
      
    
      // Map results to ensure correct data types and rename the column, keeping the same structure as before
      // const indexData = uniqueBenchmarks.map(row => ({
      //   row
      // }));      
    
      // Return the same structure: indexData and the first Symbol in the indexData
      return {indexData, indexName: indexData[0]};
    
    } catch (err) {
      console.error('Error fetching portfolio index:', err);
      // throw err;
      return {indexData, indexName: indexData[0]};;
    }
    
  }

  async function getHistoricalPrices(symbols) {
    /**
     * Fetches historical adjusted closing prices for a list of symbols.
     *
     * Args:
     *   symbols: An array of stock symbols.
     *   apiKey: Your API key for Financial Modeling Prep.
     *
     * Returns:
     *   An array of objects with 'date', 'symbol', and 'adjClose'.
     */
  
    const allPrices = [];
  
    try {
      for (const symbol of symbols) {
        const url = `https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e`;
        const response = await axios.get(url);
  
        if (response && response.historical) {
          const prices = response.historical.map(item => ({
            date: new Date(item.date).toISOString(),
            Symbol: symbol,
            adjClose: item.adjClose
          }));
          allPrices.push(...prices);
        }
      }
  
      return allPrices;
    } catch (err) {
      console.error('Error fetching historical prices:', err);
      throw err;
    }
  }
  
  async function portfolioRealTime(portfolio) {
    /**
     * Calculates the real-time value of a portfolio using current market prices.
     *
     * Args:
     *   portfolio: An array of objects with 'Symbol' and 'Share' properties.
     *   apiKey: Your API key for Financial Modeling Prep.
     *
     * Returns:
     *   The total real-time portfolio value as a number.
     */
  
    const currentPrices = {};
    console.log(portfolio, typeof portfolio);
    
  
    try {
      for (const { Symbol } of portfolio) {
        const url = `https://financialmodelingprep.com/api/v3/quote-short/${Symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e`;
        try {
          const response = await axios.get(url);
          currentPrices[Symbol] = response[0]?.price || 0;
        } catch (err) {
          console.error(`Error fetching price for ${Symbol}:`, err);
          currentPrices[Symbol] = 0;
        }
      }
  
      return portfolio.reduce((totalValue, { Symbol, Share }) => {
        const price = currentPrices[Symbol] || 0;
        return totalValue + Share * price;
      }, 0);
    } catch (err) {
      console.error('Error calculating portfolio value:', err);
      throw err;
    }
  }
  
  async function calculatePortfolioValue(portfolio, historicalPrices) {
    /**
     * Calculates the historical portfolio value for each date in the historical prices array.
     *
     * Args:
     *   portfolio: An array of objects with 'Symbol' and 'Share' properties.
     *   historicalPrices: An array of objects with 'date', 'Symbol', and 'adjClose' properties.
     *
     * Returns:
     *   An array of objects with 'date' and 'portfolioValue' properties.
     */
  
    if (!Array.isArray(historicalPrices) || !historicalPrices.every(item => item.Symbol && item.adjClose)) {
      throw new Error("Invalid historicalPrices format");
    }
  
    // Merge historical prices with portfolio data
    const mergedData = historicalPrices.map(price => {
      const portfolioItem = portfolio.find(p => p.Symbol === price.Symbol);
      return portfolioItem ? { ...price, Share: portfolioItem.Share } : null;
    }).filter(Boolean);
  
    // Calculate daily portfolio value
    const portfolioValueMap = mergedData.reduce((acc, { date, adjClose, Share }) => {
      const dailyValue = adjClose * Share;
      acc[date] = (acc[date] || 0) + dailyValue;
      return acc;
    }, {});
  
    // Convert the result to an array
    return Object.entries(portfolioValueMap).map(([date, portfolioValue]) => ({ date, portfolioValue }));
  }

  
async function getIndexName(index) {
    /**
     * Fetches the full name of an index using its symbol.
     *
     * Args:
     *   index: The symbol of the index (e.g., 'SPX').
     *   apiKey: Your API key for Financial Modeling Prep.
     *
     * Returns:
     *   The full name of the index, or null if not found.
     */
  
    const url = `https://financialmodelingprep.com/api/v3/symbol/available-indexes?apikey=11cd608ea5136b9fdb748e3a80c1724e`;
  
    try {
      const response = await axios.get(url);
  
      if (response.data && Array.isArray(response.data)) {
        const foundIndex = response.data.find(idx => idx.symbol === index);
        return foundIndex ? foundIndex.name : null;
      }
    } catch (err) {
      console.error('Error fetching index name:', err);
      return null;
    }
  
    return null; // Index not found
  }

  function normalizePortfolioValue(portfolioValueArray, indexName) {
    /**
     * Normalizes the portfolio value and index data in an array to 100 at the minimum date.
     *
     * Args:
     *   portfolioValueArray: An array of objects with 'date', 'Portfolio_Value', and index values.
     *   indexName: The name of the index to normalize.
     *
     * Returns:
     *   A new array with normalized 'Portfolio_Value' and index values.
     */
  
    if (!Array.isArray(portfolioValueArray) || !portfolioValueArray.every(item => item.date && item.portfolio_value && item[indexName])) {
      console.log("Invalid portfolioValueArray format");
      return;
    }
  
    // Find the minimum date in the data
    // const minDateEntry = portfolioValueArray.reduce((minEntry, currentEntry) => {
    //   return new Date(currentEntry.date) < new Date(minEntry.date) ? currentEntry : minEntry;
    // });
  
    const initialPortfolioValue = portfolioValueArray[0].portfolio_value;
    const initialIndexValue = portfolioValueArray[0][indexName];
  
    // Normalize the data
    return portfolioValueArray.map(entry => ({
      ...entry,
      Normalized_Portfolio_Value: (entry.portfolio_value / initialPortfolioValue) * 100,
      Normalized_Index: (entry[indexName] / initialIndexValue) * 100
    }));
  }

  
  function calculateGeometricReturns(portfolioValueData, indexName) {
    // Calculate the total return for each column
    const totalReturnPortfolio =
      (portfolioValueData[portfolioValueData.length - 1].portfolioValue /
        portfolioValueData[0].portfolioValue -
        1);
    const totalReturnIndex =
      (portfolioValueData[portfolioValueData.length - 1][indexName] /
        portfolioValueData[0][indexName] -
        1);
  
    // Calculate the number of years in the dataset
    const numYears = portfolioValueData.length / 252; // Assuming 252 trading days per year
  
    // Calculate the geometric return for 5 years, 3 years, and 1 year
    const geometricReturnPortfolio5Y =
      Math.pow(1 + totalReturnPortfolio, 1 / numYears) - 1;
    const geometricReturnIndex5Y =
      Math.pow(1 + totalReturnIndex, 1 / numYears) - 1;
  
    const geometricReturnPortfolio3Y =
      Math.pow(
        1 +
          (portfolioValueData[portfolioValueData.length - 1].portfolioValue /
            portfolioValueData[portfolioValueData.length - 252 * 3].portfolioValue -
            1),
        1 / 3
      ) - 1;
    const geometricReturnIndex3Y =
      Math.pow(
        1 +
          (portfolioValueData[portfolioValueData.length - 1][indexName] /
            portfolioValueData[portfolioValueData.length - 252 * 3][indexName] -
            1),
        1 / 3
      ) - 1;
  
    const geometricReturnPortfolio1Y =
      portfolioValueData[portfolioValueData.length - 1].portfolioValue /
        portfolioValueData[portfolioValueData.length - 252].portfolioValue -
      1;
    const geometricReturnIndex1Y =
      portfolioValueData[portfolioValueData.length - 1][indexName] /
        portfolioValueData[portfolioValueData.length - 252][indexName] -
      1;
  
    // Calculate YTD returns
    // const currentYear = new Date(portfolioValueData[portfolioValueData.length - 1].Date).getFullYear();
    // const ytdStartValuePortfolio = portfolioValueData.find(
    //   (data) => new Date(data.Date).getFullYear() === currentYear
    // ).Portfolio_Value;
    // const ytdStartValueIndex = portfolioValueData.find(
    //   (data) => new Date(data.Date).getFullYear() === currentYear
    // )[indexName];
  
    // const geometricReturnPortfolioYTD =
    //   portfolioValueData[portfolioValueData.length - 1].portfolioValue /
    //     ytdStartValuePortfolio -
    //   1;
    // const geometricReturnIndexYTD =
    //   portfolioValueData[portfolioValueData.length - 1][indexName] /
    //     ytdStartValueIndex -
    //   1;
  
    // Create an object to store the results
    const returns = [
      { Period: "5 Years", Portfolio_Value: geometricReturnPortfolio5Y * 100, [indexName]: geometricReturnIndex5Y * 100 },
      { Period: "3 Years", Portfolio_Value: geometricReturnPortfolio3Y * 100, [indexName]: geometricReturnIndex3Y * 100 },
      { Period: "1 Year", Portfolio_Value: geometricReturnPortfolio1Y * 100, [indexName]: geometricReturnIndex1Y * 100 },
      // { Period: "YTD", Portfolio_Value: geometricReturnPortfolioYTD * 100, [indexName]: geometricReturnIndexYTD * 100 },
    ];
    console.log(returns,'returns');
    
  
    return returns;
  }
  async function Client_and_Portfolio_Value(Client_ID, Portfolio_ID) {
    try {
      const { data, error } = await supabase
        .rpc('get_portfolio_value', { client_id:Client_ID, portfolio_id:Portfolio_ID });
  
      if (error) {
        console.error(error.message);
      }
  
      return data;
    } catch (error) {
      console.error('Error fetching portfolio value:', error);
      throw error;
    }
  }

    const Client_and_Portfolio_YTDandTrailing1M = async (clientId, portfolioId) => {
    const { data, error } = await supabase
      .from('PortfolioReturnYTDand1M')
      .select('Portfolio_YTD, Portfolio_Trailing1M, Index_YTD, Index_Trailing1M')
      .eq('Client_ID', clientId)
      .eq('Portfolio_ID', portfolioId)
      .order('date', { ascending: false })
      .limit(1);  // Getting the most recent data
  
    if (error) {
      console.error('Error fetching YTD1M data:', error);
      return null;
    }
    return data ? data[0] : null;
  };

  
  const Client_and_Portfolio_5Y3Yand1Y = async (clientId, portfolioId) => {
        const { data, error } = await supabase
          .from('PortfolioReturn5Y_3Y_1Y')
          .select('Portfolio_5Y, Portfolio_3Y, Portfolio_1Y, Index_5Y, Index_3Y, Index_1Y')
          .eq('Client_ID', clientId)
          .eq('Portfolio_ID', portfolioId)
          .order('date', { ascending: false })
          .limit(1);  // Getting the most recent data
      
        if (error) {
          console.error('Error fetching 5Y3Y1Y data:', error);
          return null;
        }
        return data ? data[0] : null;
      };
  
  

  export {calculateGeometricReturns, normalizePortfolioValue, getIndexName, calculatePortfolioValue, portfolioRealTime, 
    getHistoricalPrices, clientAndPortfolioIndex, clientAndPortfolioComposition, Client_and_Portfolio_Value,
    Client_and_Portfolio_YTDandTrailing1M, Client_and_Portfolio_5Y3Yand1Y
  }; 