import React, { useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { supabase } from '../supabaseClient';

const UserSync = () => {    
  const { user } = useUser();
  useEffect(() => {
    const syncUserWithSupabase = async () => {        
        
      if (!user) return;

      const { id, emailAddresses, firstName, lastName } = user;

      // Upsert the user in Supabase
      const { data, error } = await supabase
        .from('vegaveta_users')
        .upsert({
          clerk_id: id,
          email: emailAddresses[0].emailAddress,
          first_name: firstName,
          last_name: lastName,
        });

      if (error) {
        console.error('Error syncing user with Supabase:', error);
      }
      if(!localStorage.getItem('client_id') || localStorage.getItem('client_id') == ""){
      const response = await supabase
      .from('vegaveta_users')
      .select('client_id')
      .eq('clerk_id', id);
      console.log(response,'resp');
      
        localStorage.setItem('client_id',response?.data[0]?.client_id)
      }
      
      
      
      console.log('user-data--',data,error,user);
      
    };

    syncUserWithSupabase();
  }, [user]);

  return null;
};

export default UserSync;
