import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

const NormalizedLineChartComponent = ({ finalLineData }) => {
  const lineChartData = useMemo(() => {
    if (!finalLineData || finalLineData.length === 0) {
      return { categories: [], series: [] };
    }

    // Sort the data by date
    const sortedData = finalLineData.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Filter out points with large differences
    const filterLargePriceDifferences = (data) => {
      const filteredData = [];
      for (let i = 0; i < data.length; i++) {
        if (
          (i > 0 && Math.abs(data[i].portfolioValue - data[i - 1].Normalized_Portfolio_Value) <= 500) &&
          (i < data.length - 1 && Math.abs(data[i].Normalized_Portfolio_Value - data[i + 1].Normalized_Portfolio_Value) <= 500)
        ) {
          filteredData.push(data[i]);
        }
      }
      return filteredData;
    };

    // const filteredData = filterLargePriceDifferences(sortedData);
    const filteredData = sortedData;

    // Extract values and labels
    const categories = filteredData.map((dataPoint) => dataPoint.date.split('T')[0]);
    const portfolioValues = filteredData.map((dataPoint) => dataPoint.Normalized_Portfolio_Value); // Corrected this line
    const normalizedIndexValues = filteredData.map((dataPoint) => dataPoint.Normalized_Index); // Corrected this line

    return {
      categories,
      series: [
        {
          name: 'Normalized Portfolio',
          data: portfolioValues,
        },
        {
          name: 'Normalized Index',
          data: normalizedIndexValues,
          yAxisIndex: 1, // This ensures the 'Normalized Index' is plotted on the right y-axis
        },
      ],
    };
  }, [finalLineData]);

  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        tools: {
          zoomin: true,
          zoomout: true,
          pan: true,
        },
      },
      zoom: {
        enabled: true, // Enable zooming
        type: 'xy', // Enable zooming on both axes
      },
    },
    stroke: {
      curve: 'smooth', // Make the line smooth
      width: 3, // Thin line
    },
    xaxis: {
      categories: lineChartData.categories,
      tickAmount: 15,
    },
    yaxis: [
      {
        title: { text: 'Portfolio Value' },
        labels: {
          formatter: function (value) {
            return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
          },
        },
      },
      {
        opposite: true, // Place this y-axis on the right
        title: { text: 'Index Value' },
        labels: {
          formatter: function (value) {
            return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
          },
        },
      },
    ],
    markers: {
      size: 0, // Remove circle markers on the line
    },
    colors: ['#FF6384', '#36A2EB'], // Portfolio and Index colors
    tooltip: {
      shared: true,
      intersect: false,
    },
  };

  return (
    <div>
      <Chart
        options={chartOptions}
        series={lineChartData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default NormalizedLineChartComponent;
