import React, { useState, useEffect } from "react";
import { VectorMap } from "@south-paw/react-vector-maps";
import { Bar } from "react-chartjs-2";
import { supabase } from "../supabaseClient";
import { iso_codes } from "../assets/country_iso_codes";
import * as worldLowRes from "../common/world.svg.json";
import styled from "styled-components";

// Styled component for the Bar Chart
const StyledBarChart = styled.div`
  width: 100%;
  height: 400px; /* Adjust this value to control the size */
  margin-top: 20px;
`;

const Tooltip = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  pointer-events: none; // Prevent tooltip from interfering with mouse events
`;

// Styled component for the table
const Table1 = styled.table`
  border-collapse: collapse;
  margin-top: 10px;
//   margin-right: 10px;

  th,
  td {
    padding: 20px;
    border: 1px solid gray;
    text-align: left;
    width: 100px;
  }

  th {
    background-color: rgb(55 65 81);
    color: #fff;
  }

  td {
    background-color: ${({ theme }) => theme.cardColor};
  }

  tbody {
    display: block;
    overflow-y: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1; /* Ensure header stays above the content */
  }
`;

const Card = styled.div`
  width: 50%;
  height: 500px;
  background-color: ${({ theme }) => theme.cardColor};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 10px;

  &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  }
`;


const StatisticsContainer = styled.div`
  display: flex;
//   max-width: 100%;
  gap: 20px;
//   flex-wrap: wrap;
`;

const Map = styled.div`
  width: 100%; 
  // height: 00px; // Set a fixed height
  // border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  // background: ${({ theme }) => theme.cardBackground};
  padding: 20px;
  // margin-left:-5%;

  svg {
    width: 100%;
    max-height: 380px;
    stroke: #fff;
    stroke: #fff;
margin-top:5%;

    path {
      fill: #c0c0c0; // Default color for regions
      cursor: pointer;
      outline: none;

      &:hover {
        fill: rgba(168, 43, 43, 0.83); // Color on hover
      }
      &:focus {
        fill: rgba(168, 43, 43, 0.6);
      }
      &[aria-checked='true'] {
        fill: rgba(56, 43, 168, 0.83); // Selected region color
      }
    
    }
  }
`;


const PortfolioMap = ({ clientId = localStorage.getItem("client_id"), portfolioId }) => {
    const [data, setData] = useState([]);
    const [mapData, setMapData] = useState({});
    const [hoveredCountry, setHoveredCountry] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [sectorData, setSectorData] = useState(null);
    const [industryData, setIndustryData] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [hoveredW, setHoveredW] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const { data: portfolioData, error } = await supabase
                .from("PortfolioReturn_ByCountrySectorIndustry")
                .select("*")
                .eq("Client_ID", clientId)
                .eq("Portfolio_ID", portfolioId);

            if (error) {
                console.error("Error fetching data:", error);
            } else {
                portfolioData.forEach((item) => {
                    item["iso_code"] = item.Country;
                    item["Country"] = iso_codes[item.Country];
                });

                setData(portfolioData);

                const groupedByCountry = portfolioData.reduce((acc, item) => {
                    acc[item.iso_code] = acc[item.iso_code] || { totalReturn: 0, count: 0 };
                    acc[item.iso_code].totalReturn += parseFloat(item.Return);
                    acc[item.iso_code].count += 1;
                    return acc;
                }, {});

                setMapData(groupedByCountry);
            }
        };

        fetchData();
    }, [clientId, portfolioId]);

    const handleCountryClick = (country) => {
        
        const filteredData = data.filter((item) => item.iso_code === country.toUpperCase());
        console.log(filteredData);
        
        if(filteredData.length == 0){
            alert('No data available');
            return;
        }
        const groupedBySector = filteredData.reduce((acc, item) => {
            acc[item.Sector] = acc[item.Sector] || 0;
            acc[item.Sector] += parseFloat(item.Return);
            return acc;
        }, {});
        
        setSelectedCountry(country);
        setSectorData(groupedBySector);
        
    };
    useEffect(() => {
        if (selectedCountry && sectorData) {
            const firstSector = Object.keys(sectorData)[0];
            if (firstSector) {
                handleSectorBarClick(firstSector);
            }
        }
    }, [selectedCountry, sectorData]);

    useEffect(() => {
        if (selectedCountry && industryData) {
            const firstInd = Object.keys(industryData)[0];
            if (firstInd) {
                handleIndustryBarClick(firstInd);
            }
        }
    }, [selectedCountry, industryData]);

    const handleSectorBarClick = (sector) => {
        
        
        const filteredData = data.filter(
            (item) => item.iso_code === selectedCountry.toUpperCase() && item.Sector === sector
        );

        const groupedByIndustry = filteredData.reduce((acc, item) => {
            acc[item.Industry] = acc[item.Industry] || 0;
            acc[item.Industry] += parseFloat(item.Return);
            return acc;
        }, {});

        setIndustryData(groupedByIndustry);
        
        if(groupedByIndustry)
            handleIndustryBarClick(Object.keys(groupedByIndustry)[0].Industry)
        // setTableData([]);
    };

    const handleIndustryBarClick = (industry) => {
        const selectedSector = data.find(item =>
            item.iso_code === selectedCountry.toUpperCase() && item.Industry === industry
        )?.Sector;

        if (!selectedSector) {
            console.error("Sector not found for the selected industry.");
            return;
        }

        const filteredData = data.filter(
            (item) =>
                item.iso_code === selectedCountry.toUpperCase() &&
                item.Sector === selectedSector &&
                item.Industry === industry
        );

        setTableData(filteredData);
    };

    useEffect(() => {
    const fetchData = async () => {
        const { data: portfolioData, error } = await supabase
            .from("PortfolioReturn_ByCountrySectorIndustry")
            .select("*")
            .eq("Client_ID", clientId)
            .eq("Portfolio_ID", portfolioId);

        if (error) {
            console.error("Error fetching data:", error);
        } else {
            portfolioData.forEach((item) => {
                item["iso_code"] = item.Country;
                item["Country"] = iso_codes[item.Country];
            });

            setData(portfolioData);

            const groupedByCountry = portfolioData.reduce((acc, item) => {
                acc[item.iso_code] = acc[item.iso_code] || { totalReturn: 0, count: 0 };
                acc[item.iso_code].totalReturn += parseFloat(item.Return);
                acc[item.iso_code].count += 1;
                return acc;
            }, {});

            setMapData(groupedByCountry);

            // Set default selections
            if (portfolioData.length > 0) {
                const defaultCountry = portfolioData[0].iso_code;
                const filteredByCountry = portfolioData.filter(item => item.iso_code === defaultCountry);

                setSelectedCountry(defaultCountry);

                const groupedBySector = filteredByCountry.reduce((acc, item) => {
                    acc[item.Sector] = acc[item.Sector] || 0;
                    acc[item.Sector] += parseFloat(item.Return);
                    return acc;
                }, {});
                setSectorData(groupedBySector);

                const defaultSector = Object.keys(groupedBySector)[0];
                if (defaultSector) {
                    const filteredBySector = filteredByCountry.filter(item => item.Sector === defaultSector);

                    const groupedByIndustry = filteredBySector.reduce((acc, item) => {
                        acc[item.Industry] = acc[item.Industry] || 0;
                        acc[item.Industry] += parseFloat(item.Return);
                        return acc;
                    }, {});
                    setIndustryData(groupedByIndustry);
                    setTableData(filteredBySector.filter(item => item.Sector === defaultSector));
                }
            }
        }
    };

    fetchData();
}, [clientId, portfolioId]);



    const layerPropsWorld = {
        onMouseEnter: ({ target }) => {
            //   console.log(target);

            const countryName = target.ariaLabel; // Get the country name
            const value = mapData[target.id.toUpperCase()]?.totalReturn.toFixed(2).toString();
            if (value)
                setHoveredW(countryName + " - " + value);
            else
                setHoveredW(countryName);
            const rect = target.getBoundingClientRect(); // Get the position of the target element
            setTooltipPosition({
                top: rect.top - 250, // Position above the element
                left: rect.left + rect.width / 2, // Center it horizontally
            });
        },
        onMouseLeave: () => {
            setHoveredW(null);
        },
        onClick: ({ target }) => {
            handleCountryClick(target.attributes.id.value)
        },

    };

    const getMapMarkers = () => {
        // Debugging the map data to check if it's properly structured
        
    
        return Object.keys(mapData).map((isoCode) => {
            const country = iso_codes[isoCode]; // Get the ISO code for the country
    
            // Set the fill color to red if the country has data in mapData
            const fillColor = mapData[country]?.totalReturn ? "#ff6347" : "#cccccc"; // Red if data exists, gray if not
        
    
            return {
                id: isoCode, // Use the ISO code as the marker id
                attributes: {
                    fill: fillColor, // Set marker color
                    title: country,  // Tooltip to display the ISO code
                },
                // Optional: You can add other customizations like coordinates here
            };
        }).filter(marker => marker !== null); // Filter out any invalid markers
    };
    

    return (
        <div className="portfolio-return-container">
            <StatisticsContainer>
                <Card>
                    {/* <h3>World Map</h3> */}
                    <div className="map-container" style={{ position: "relative", width: "600px", height: "400px" }}>
                        <Map>
                            <VectorMap
                                {...worldLowRes}
                                layerProps={layerPropsWorld}
                                currentLayers={hoveredW}
                                checkedLayers={[selectedCountry]}
                                markers={getMapMarkers()}
                            />

                        </Map>
                        {hoveredW && (

                            <Tooltip style={{
                                top: tooltipPosition.top,
                                left: tooltipPosition.left,
                                transform: 'translate(-50%, -100%)' // Position above the cursor
                            }}>
                                {hoveredW}
                            </Tooltip>
                        )}
                        {hoveredCountry && (
                            <div className="tooltip">
                                <p>{hoveredCountry}</p>
                                <p>
                                    Cumulative Return: {mapData[hoveredCountry]?.totalReturn?.toFixed(2) || "N/A"}
                                </p>
                            </div>
                        )}
                    </div>
                </Card>

                {sectorData && (
                    <Card>
                        <h3>Sector-wise Returns</h3>
                        <StyledBarChart>
                            <Bar
                                data={{
                                    labels: Object.keys(sectorData),
                                    datasets: [
                                        {
                                            label: "Return",
                                            data: Object.values(sectorData),
                                            backgroundColor: "#4CAF50",
                                        },
                                    ],
                                }}
                                options={{
                                    indexAxis: "y", // Makes the bars horizontal
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            beginAtZero: true,
                                        },
                                    },
                                    barPercentage: 0.5, // Narrow bars
                                    categoryPercentage: 1.0, // Full width for bars
                                    onClick: (evt, element) => {
                                        if (element.length > 0) {
                                            const sector = Object.keys(sectorData)[element[0].index];
                                            handleSectorBarClick(sector);
                                        }
                                    },
                                }}
                            />
                        </StyledBarChart>
                    </Card>
                )}
            </StatisticsContainer>

            <StatisticsContainer>
                {industryData && (
                    <Card>
                        <h3>Industry-wise Returns</h3>
                        <StyledBarChart>
                            <Bar
                                data={{
                                    labels: Object.keys(industryData),
                                    datasets: [
                                        {
                                            label: "Return",
                                            data: Object.values(industryData),
                                            backgroundColor: "#FFA726",
                                        },
                                    ],
                                }}
                                options={{
                                    indexAxis: "y", // Makes the bars horizontal
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            beginAtZero: true,
                                        },
                                    },
                                    barPercentage: 0.5, // Narrow bars
                                    categoryPercentage: 1.0, // Full width for bars
                                    onClick: (evt, element) => {
                                        if (element.length > 0) {
                                            const industry = Object.keys(industryData)[element[0].index];
                                            handleIndustryBarClick(industry);
                                        }
                                    },
                                }}
                            />
                        </StyledBarChart>
                    </Card>
                )}

                {tableData.length > 0 && (
                    <Card>
                        <h3>Details Table</h3>
                        <Table1>
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>Sector</th>
                                    <th>Industry</th>
                                    <th>Return</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((item, idx) => (
                                    <tr key={idx}>
                                        <td>{item.Country}</td>
                                        <td>{item.Sector}</td>
                                        <td>{item.Industry}</td>
                                        <td>{item.Return.toFixed(4)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table1>
                    </Card>
                )}
            </StatisticsContainer>
        </div>
    );
};

export default PortfolioMap;
