import React from 'react';
import ApexCharts from 'react-apexcharts';

const LineChart = ({ allHistoricalData, lineSettings }) => {
  console.log(allHistoricalData, lineSettings);

  // Prepare the chart data for multiple symbols
  const chartData = {
    series: Object.keys(allHistoricalData).map((symbol) => {
      const data = allHistoricalData[symbol]?.historicalData || [];

      // Ensure the data is in the correct format, focusing on 'close' price
      const seriesData = data.map((item) => {
        if (item && item.x && item.y) {
          return { x: item.x, y: item.y }; // Use the date for x and close price for y-axis
        }
        return null; // If data is invalid or missing, return null
      }).filter(Boolean); // Remove null entries from the array

      return {
        name: symbol, // Use the stock symbol as the name of the series
        data: seriesData, // The actual data points (e.g., stock close prices)
      };
    }),

    options: {
      chart: {
        type: 'line',
        height: '100%',
      },
      xaxis: {
        type: 'datetime', // Ensure x-axis is treated as datetime for proper date formatting
        categories: allHistoricalData[Object.keys(allHistoricalData)[0]]?.historicalData?.map((item) => item.date) || [],
        title: {
          text: 'Date',
        },
      },
      yaxis: {
        title: {
          text: 'Close Price', // Label updated to 'Close Price'
        },
      },
      title: {
        text: 'Stock Price History (Close)',
        align: 'center',
      },
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          format: 'dd MMM yyyy', // Tooltip date format
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
      },
      stroke: {
        width: Object.keys(allHistoricalData).map((symbol) => {
          // Apply line width settings for each symbol if available
          return lineSettings[symbol]?.lineWidth || 3;
        }),
        colors: Object.keys(allHistoricalData).map((symbol) => {
          // Apply line color settings for each symbol if available
          return lineSettings[symbol]?.lineColor || '#f00';
        }),
      },
    },
  };

  return (
    <div>
      <ApexCharts options={chartData.options} series={chartData.series} type="line" height={350} />
    </div>
  );
};

export default LineChart;
