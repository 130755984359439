import React, { useState, useEffect } from 'react';
import LineChart from './MultiLineChart'; // Ensure this supports multiple lines
import DateRangeSelector from './DateRangeSelector';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../../pages/Header';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
    background: '#f0f0f0',
    color: '#222',
    primary: '#AC544C',
    secondary: '#b06252',
    captionColor: '#666'
};

const darkTheme = {
    background: 'rgb(31 41 55)',
    color: '#f0f0f0',
    primary: '#AC544C',
    secondary: '#b06252',
    captionColor: '#ccc'
};

const Sidebar = styled.div`
  width: 400px;
  background-color: white;
  padding: 20px;
  position: fixed; /* Use fixed positioning to overlay on the page */
  top: 10;
  right: -300px; /* Initially hide the sidebar off-screen */
  height: 100%;
  transition: right 0.3s ease;
  z-index: 100; /* Ensure it overlays above the content */
//   border: solid 1px;
  box-shadow: 0 19px 38px rgba(0,0,0,.3),0 15px 12px rgba(0,0,0,.22);
`;

const SidebarOpen = styled(Sidebar)`
  right: 0; /* Slide in when the sidebar is open */
`;

const ChartPageContainer = styled.div`
  display: flex;
  margin-top: 90px;
  background-color: #f4f4f9;
  position: relative;
  padding-left: 320px; /* Add padding to the left when sidebar is open */
  transition: padding-left 0.3s ease;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1;
  margin-right: 320px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #1abc9c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 10%;
  margin-top: 5px;
  float:right;
  &:hover {
    background-color: #16a085;
  }
`;

const Button1 = styled.button`
  padding: 10px;
  background-color: #1abc9c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
//   float:right;
  &:hover {
    background-color: #16a085;
  }
`;

const SearchContainer = styled.div`
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Tab = styled.div`
  padding: 10px;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#ddd' : 'transparent')};
  flex: 1;
  text-align: center;
  &:hover {
    background-color: #eee;
  }
`;

const TabContent = styled.div`
  margin-top: 10px;
`;

const CloseButton = styled.button`  
  padding: 8px 16px;
  background-color: white;
  color: ${({ theme }) => theme.color};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto;
  float:right

  &:hover {
    background-color: #eee;
  }
`;

const Title = styled.h3`
color: #1abc9c;
font-weight:bold;
`;

const ChartPage = () => {
    const [selectedSymbol, setSelectedSymbol] = useState('AAPL');
    const [dateRange, setDateRange] = useState('1D');
    const [customStartDate, setCustomStartDate] = useState('');
    const [customEndDate, setCustomEndDate] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('editLine');
    const [linesToAdd, setLinesToAdd] = useState([]);
    const [allHistoricalData, setAllHistoricalData] = useState({
        AAPL: { symbol: 'AAPL', historicalData: [] },
    });

    const [chartFormat, setChartFormat] = useState({});

const handleLineWidthChange = (key, width) => {
  setChartFormat((prevState) => ({
    ...prevState,
    [key]: {
      ...prevState[key],
      lineWidth: width,
    },
  }));
};

const handleLineColorChange = (key, color) => {
  setChartFormat((prevState) => ({
    ...prevState,
    [key]: {
      ...prevState[key],
      lineColor: color,
    },
  }));
};

    const getToday = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    useEffect(() => {
        const from_date = customStartDate || getToday();
        const to_date = customEndDate || getToday();

        const symbolsToFetch = Object.keys(allHistoricalData); // Assuming linesToAdd contains additional symbols to display
        console.log(symbolsToFetch);


        // Function to fetch data for a symbol
        const fetchDataForSymbol = (symbol) => {
            const url =
                dateRange !== '1D'
                    ? `https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e&from=${from_date}&to=${to_date}`
                    : `https://financialmodelingprep.com/api/v3/historical-chart/1min/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e`;

            return fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    let historicalData = dateRange !== '1D' ? data.historical || [] : data;

                    // Filter the data based on the selected date range
                    if (dateRange !== '1D') {
                        historicalData = historicalData.filter((item) => {
                            const itemDate = new Date(item.date);
                            const startDate = new Date(from_date);
                            const endDate = new Date(to_date);

                            return itemDate >= startDate && itemDate <= endDate;
                        });
                    }

                    return {
                        symbol,
                        historicalData: historicalData.map((item) => ({
                            x: item.date,
                            y: item.adjClose || item.close,
                        })),
                    };
                })
                .catch((error) => {
                    console.error(`Error fetching data for symbol ${symbol}:`, error);
                    return { symbol, historicalData: [] };
                });
        };

        // Fetch data for all selected symbols
        Promise.all(symbolsToFetch.map(fetchDataForSymbol))
            .then((results) => {
                setAllHistoricalData((prevData) => {
                    const updatedData = { ...prevData };

                    // For each symbol, update only the historicalData field
                    results.forEach(({ symbol, historicalData }) => {
                        updatedData[symbol] = {
                            ...updatedData[symbol], // Keep the other fields intact
                            historicalData,
                        };
                    });

                    return updatedData;
                });
            });
    }, [linesToAdd, dateRange, customStartDate, customEndDate]);

    useEffect(() => {
        const from_date = customStartDate || getToday();
        const to_date = customEndDate || getToday();

        const symbolsToFetch = [selectedSymbol, ...linesToAdd]; // Assuming linesToAdd contains additional symbols to display
        console.log(symbolsToFetch, 'gg');


        // Function to fetch data for a symbol
        const fetchDataForSymbol = (symbol) => {
            const url =
                dateRange !== '1D'
                    ? `https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e&from=${from_date}&to=${to_date}`
                    : `https://financialmodelingprep.com/api/v3/historical-chart/1min/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e`;

            return fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    let historicalData = dateRange !== '1D' ? data.historical || [] : data;

                    // Filter the data based on the selected date range
                    if (dateRange !== '1D') {
                        historicalData = historicalData.filter((item) => {
                            const itemDate = new Date(item.date);
                            const startDate = new Date(from_date);
                            const endDate = new Date(to_date);

                            return itemDate >= startDate && itemDate <= endDate;
                        });
                    }

                    return {
                        symbol,
                        historicalData: historicalData.map((item) => ({
                            x: item.date,
                            y: item.adjClose || item.close,
                        })),
                    };
                })
                .catch((error) => {
                    console.error(`Error fetching data for symbol ${symbol}:`, error);
                    return { symbol, historicalData: [] };
                });
        };

        // Fetch data for all selected symbols
        Promise.all(symbolsToFetch.map(fetchDataForSymbol))
            .then((results) => {
                setAllHistoricalData((prevData) => {
                    const updatedData = { ...prevData };

                    // For each symbol, update only the historicalData field
                    results.forEach(({ symbol, historicalData }) => {
                        updatedData[symbol] = {
                            ...updatedData[symbol], // Keep the other fields intact
                            historicalData,
                        };
                    });

                    return updatedData;
                });
            });
    }, [selectedSymbol]);

    useEffect(() => {
        if (searchQuery.length >= 2) {
            fetch(
                `https://financialmodelingprep.com/api/v3/search?query=${searchQuery}&apikey=11cd608ea5136b9fdb748e3a80c1724e`
            )
                .then((response) => response.json())
                .then((data) => setSearchResults(data))
                .catch((error) => console.error('Error fetching search results:', error));
        } else {
            setSearchResults([]);
        }
    }, [searchQuery]);

    const handleSymbolSelect = (symbol) => {
        setSelectedSymbol(symbol);
        setSearchQuery('');
        setSearchResults([]);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleAddLine = (symbol) => {
        if (!linesToAdd.includes(symbol)) {
            setLinesToAdd((prevLines) => [...prevLines, symbol]);
            setSelectedSymbol(symbol);

        }
        setSearchQuery('');
        setSearchResults([]);
    };

    const handleDateRangeChange = (range, activeRange) => {

        console.log(range);

        setDateRange(range);
        setCustomStartDate(range);
        setDateRange(activeRange);        
        setCustomEndDate();
    };


    return (
        <ThemeProvider theme={lightTheme}>
            <GlobalStyle />
            <Header />
            <ChartPageContainer>
                <Card>
                    {/* <h3>{`Stock Symbol: ${selectedSymbol}`}</h3> */}
                    {!isSidebarOpen &&
                    <SearchContainer>
                        <SearchInput
                            type="text"
                            placeholder="Search for a stock..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        {searchResults.map((result) => (
                            <Button1 key={result.symbol} onClick={() => handleSymbolSelect(result.symbol)}>
                                {result.name} ({result.symbol})
                            </Button1>
                        ))}
                    </SearchContainer>
                    }
                    <Button onClick={toggleSidebar}>Edit Graph</Button>
                    <DateRangeSelector
                        onDateRangeChange={handleDateRangeChange}
                        // onDateRangeChange={(range) => setDateRange(range)}
                        onCustomDateChange={(startDate, endDate) => {
                            setCustomStartDate(startDate);
                            setCustomEndDate(endDate);
                        }}
                    />

                    {Object.keys(allHistoricalData).length > 0 && (
                        <LineChart allHistoricalData={allHistoricalData} lineSettings={chartFormat}  />
                    )}

                </Card>
                {isSidebarOpen && (
                    <SidebarOpen>
                        {/* <h3>Edit Graph</h3> */}
                        <TabContainer>
                            <Tab onClick={() => setActiveTab('editLine')} isActive={activeTab === 'editLine'}>Edit Line</Tab>
                            <Tab onClick={() => setActiveTab('add')} isActive={activeTab === 'add'}>Add</Tab>
                            <Tab onClick={() => setActiveTab('format')} isActive={activeTab === 'format'}>Format</Tab>
                            <CloseButton onClick={() => setIsSidebarOpen(false)}><i className="fa fa-times"></i></CloseButton>
                        </TabContainer>
                        <TabContent>
                            {activeTab === 'editLine' && <div>Edit line content here.</div>}
                            {activeTab === 'add' && (
                                <div>
                                    <SearchInput
                                        type="text"
                                        placeholder="Search to add a line..."
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    {searchResults.map((result) => (
                                        <Button1 key={result.symbol} onClick={() => handleAddLine(result.symbol)}>
                                            {result.name} ({result.symbol})
                                        </Button1>
                                    ))}
                                </div>
                            )}
                            {activeTab === 'format' && 
   <div>
    <hr/>
   {Object.keys(allHistoricalData).map((key,index) => (
     <div key={key}>
       <Title>Line {index+1} - {key}:</Title>

       {/* Line width selection */}
       <label htmlFor={`line-width-${key}`} style={{marginLeft:'5px'}}>Line Width: </label>
       <select
         id={`line-width-${key}`}
         onChange={(e) => handleLineWidthChange(key, e.target.value)}
         value={chartFormat[key]?.lineWidth || 3}
         style={{marginLeft:'20px'}}
       >
         <option value="1">1px</option>
         <option value="2">2px</option>
         <option value="3">3px</option>
       </select>

       {/* Line color selection */}
       <label htmlFor={`line-color-${key}`} style={{marginLeft:'20px'}}>Line Color: </label>
       <input
         type="color"
         id={`line-color-${key}`}
         value={chartFormat[key]?.lineColor || '#f00'}
         onChange={(e) => handleLineColorChange(key, e.target.value)}
         style={{marginLeft:'20px'}}
       />
       <hr/>
     </div>
   ))}
 </div>

}

                        </TabContent>
                    </SidebarOpen>
                )}
            </ChartPageContainer>
        </ThemeProvider>
    );
};

export default ChartPage;
