
import React, { useState, useEffect, useMemo } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { calculatePortfolioValue, clientAndPortfolioComposition, getHistoricalPrices, portfolioRealTime, clientAndPortfolioIndex,
   normalizePortfolioValue, calculateGeometricReturns, Client_and_Portfolio_Value, Client_and_Portfolio_YTDandTrailing1M, Client_and_Portfolio_5Y3Yand1Y } from './PortfolioFunctions';
import Header from '../Header';  // Assuming you have a Header component
import LineChartComponent from './LineChart';
import Chart from 'react-apexcharts';
import NormalizedLineChartComponent from './NormalizedLineChart';
import BarChartComponent from './BarChartComponent';
import { useUser } from '@clerk/clerk-react';
import { supabase } from '../../supabaseClient';

// Registering Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

// Global Styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

// Themes
const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  backgroundColor: `rgb(55 65 81)`,
  cardColor: '#fff'
};

const darkTheme = {
  background: 'rgb(31 41 55)',
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
  backgroundColor: `rgb(55 65 81)`,
  cardColor: `rgb(55 65 81)`
};

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  // padding: 20px;
//   background-color: ${({ theme }) => theme.background};
//   color: ${({ theme }) => theme.color};
  // min-height: 90vh;
  // margin-top:100px;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const ControlGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const ControlGroup1 = styled.div`
  display: flex;
  flex-direction: column;
  // margin:5px
  // gap: 550px;
  // float: right;  
`;

const DropdownLabel = styled.label`
  font-size: 16px;
`;

const ControlRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TableContainer = styled.div`
  margin-top: 20px;
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 10px;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: white;

  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const ChartBox = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.cardColor};;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
`;

const Title = styled.h3`
  text-align: center;
  color: white;
`;

const Label = styled.label`
  margin-left: 10px;
`

const PortfolioNew = ({pfId, theme1}) => {
  const [selectedPortfolio, setSelectedPortfolio] = useState('Portfolio 1');
  const [isRealTime, setIsRealTime] = useState(false);
  const [isNormalized, setIsNormalized] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const [realTimeValue, setRealTimeValue] = useState(null);
  const [symbols, setSymbols] = useState([]);
  const [finalLineData, setFinalLineData] = useState([]);
  const [historicalIndexData, setHistoricalIndexData] = useState([]);
  const [indexName, setIndexName] = useState([]);
  const [normalizedData, setNormalizedData] = useState([]);
  const [geometricData, setGeometricData] = useState([]);
  const [globalClientId, setGlobalClientId] = useState(localStorage.getItem('client_id'));
  const [globalPfId, setGlobalPfId] = useState(1);
  const [globalPfIds, setGlobalPfIds] = useState([1]);

  useEffect(() => {
    setGlobalPfId(pfId);
  }, [pfId]);

  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });
  const clientIdNumber = localStorage.getItem('client_id');
  
  // Fetch Portfolio Data
  const fetchPortfolioData = async () => {
    try {
      
      
      const client_data = await clientAndPortfolioComposition(globalClientId);
      setGlobalPfIds(Object.keys(client_data));

      const portfolioSymbols = client_data[globalPfId].map((item) => item.Symbol);
      console.log(client_data,'--client_data--');
      
      setSymbols(portfolioSymbols);
      setPortfolioData(client_data);
    } catch (error) {
      console.error('Error fetching portfolio data:', error);
    }
  };

  // Fetch Real-Time Value
  const fetchRealTimeValue = async (data) => {
    try {
      console.log(data,'--data--');
      
      const realTimeVal = await portfolioRealTime(data[globalPfId]);
      setRealTimeValue(Number(realTimeVal.toFixed(2)));
    } catch (error) {
      console.error('Error fetching real-time value:', error);
    }
  };

  // Fetch Historical and Calculated Data
  const fetchAdditionalData = async (symbols, portfolioData) => {
    try {
      const historicalData = await getHistoricalPrices(symbols);
      const finalData = await calculatePortfolioValue(portfolioData[globalPfId], historicalData);

      setFinalLineData(finalData);
    } catch (error) {
      console.error('Error fetching additional data:', error);
    }
  };

  // Fetch portfolio data on component mount
  useEffect(() => {
    fetchPortfolioData();
  }, [pfId]);

  // Periodic real-time updates
  useEffect(() => {
    if (portfolioData && portfolioData[globalPfId]?.length === 0) return;

    const interval = setInterval(() => {
      fetchRealTimeValue(portfolioData);
    }, 10000000);

    return () => clearInterval(interval);
  }, [portfolioData]);

  const mergeDataFrames = (df1, df2) => {
    const map = new Map();
  
    // Add data from the first array
    df1.forEach((row) => {
      map.set(row.date, { ...row });
    });
  
    // Merge data from the second array
    df2.forEach((row) => {
      if (map.has(row.date)) {
        map.set(row.date, { ...map.get(row.date), ...row });
      } else {
        map.set(row.date, { ...row });
      }
    });
  
    // Convert the map back to an array
    return Array.from(map.values());
  };

  const fetchHistoricalIndexData = async() => {
    

    let {indexData, indexName} = await clientAndPortfolioIndex(globalClientId, globalPfId);
    console.log('indexData--', indexData, indexName);
    
    const historicalIndexData = await getHistoricalPrices(indexData);
    const renamedhistoricalIndexData = await historicalIndexData.map(item => {
      const { adjClose, ...rest } = item; 
      return { ...rest, [indexName]: adjClose }; 
    });
    console.log(renamedhistoricalIndexData,'rename');
    
    setHistoricalIndexData(renamedhistoricalIndexData);
    setIndexName(indexName);

    const normalizedDataValue = await Client_and_Portfolio_Value(globalClientId, globalPfId);    
    const mergedPortfolioValue = normalizedDataValue?.map((portfolioRow, index) => {
      const indexRow = renamedhistoricalIndexData[index];
      // console.log(portfolioRow,'dsa',indexRow);
      
      
      if (indexRow && portfolioRow.Date === indexRow?.Date) {
        return { ...portfolioRow, ...indexRow };  // Merging the objects
      }
    
      return null;  // If dates don't match, you could handle accordingly (e.g., returning null or skipping)
    }).filter(row => row !== null);  // Remove null entries in case of unmatched rows
    
    // const mergedPortfolioValue = mergeDataFrames(
    //   portfolioData,
    //   renamedhistoricalIndexData
    // );
    console.log(mergedPortfolioValue,'mrg');
    
    
    const normalizedData = normalizePortfolioValue(mergedPortfolioValue, indexName);
    console.log(normalizedData,'nm');



    // const geometricData = calculateGeometricReturns(mergedPortfolioValue, indexName);
    const ytdTrailingData = await Client_and_Portfolio_YTDandTrailing1M(globalClientId, globalPfId);
    
    // Fetching 5Y, 3Y, and 1Y data
    const y5y3y1yData = await Client_and_Portfolio_5Y3Yand1Y(globalClientId, globalPfId);
    if (ytdTrailingData && y5y3y1yData) {
        setGeometricData({
          categories: [ '5Y', '3Y', '1Y','YTD', 'Trailing 1M'],
          series: [
            {
              name: 'Portfolio',
              data: [
                
                y5y3y1yData.Portfolio_5Y,
                y5y3y1yData.Portfolio_3Y,
                y5y3y1yData.Portfolio_1Y,
                ytdTrailingData.Portfolio_YTD,
                ytdTrailingData.Portfolio_Trailing1M,
              ],
            },
            {
              name: 'Index',
              data: [
                
                y5y3y1yData.Index_5Y,
                y5y3y1yData.Index_3Y,
                y5y3y1yData.Index_1Y,
                ytdTrailingData.Index_YTD,
                ytdTrailingData.Index_Trailing1M,
              ],
            },
          ],
        });
      }
    

    console.log('bar', ytdTrailingData,y5y3y1yData);
    

    // setGeometricData(geometricData);
    
    setNormalizedData(normalizedData);
  }
  useEffect(() => {
    if (portfolioData && portfolioData[globalPfId]?.length === 0) return;
    fetchHistoricalIndexData();
  }, [finalLineData, isNormalized]);

  // Fetch additional data when symbols are available
  useEffect(() => {
    if (symbols.length > 0) {
      fetchAdditionalData(symbols, portfolioData);
    }
  }, [symbols, portfolioData]);

  // Prepare line chart data
  const lineChartData = useMemo(() => {
    const finalData = finalLineData;
    if (!finalData || finalData.length === 0) {
      return { labels: [], datasets: [] };
    }
  
    // Sort the data by date and reverse it to have the latest dates first
    const sortedData = finalData.sort((a, b) => new Date(a.date) - new Date(b.date));
  
    // Function to filter out points with a difference of more than $500 compared to the previous or next value
    const filterLargePriceDifferences = (data) => {
      const filteredData = [];
      for (let i = 0; i < data.length; i++) {
        if (
          (i > 0 && Math.abs(data[i].portfolioValue - data[i - 1].portfolioValue) <= 500) &&
          (i < data.length - 1 && Math.abs(data[i].portfolioValue - data[i + 1].portfolioValue) <= 500)
        ) {
          filteredData.push(data[i]);
        }
      }
      return filteredData;
    };
  
    // Filter out portfolio and index values based on the price difference condition
    const filteredData = filterLargePriceDifferences(sortedData);
  
    // Extract portfolio and index values after filtering
    const portfolioValues = filteredData.map((dataPoint) => dataPoint.portfolioValue);
    const indexValues = filteredData.map((dataPoint) => dataPoint.indexValue);
  
    // Generate the labels (dates) based on the filtered data
    const labels = filteredData.map((dataPoint) => dataPoint.date.split('T')[0]);
  
    return {
      labels,
      datasets: [
        {
          label: 'Portfolio',
          data: portfolioValues,
          borderColor: 'rgb(255, 99, 132)',
          fill: false,
        },
        {
          label: 'Index',
          data: indexValues,
          borderColor: 'rgb(54, 162, 235)',
          fill: false,
        },
      ],
    };
  }, [finalLineData]);
  
  const normalizedLineChartData = useMemo(() => {
    if (!normalizedData || normalizedData.length === 0) {
        return { labels: [], datasets: [] };
    }

    const sortedData = normalizedData.sort((a, b) => new Date(a.date) - new Date(b.date));

    const filterLargePriceDifferences = (data) => {
      const filteredData = [];
      for (let i = 0; i < data.length; i++) {
        if (
          (i > 0 && Math.abs(data[i].Normalized_Portfolio_Value - data[i - 1].Normalized_Portfolio_Value) <= 10) &&
          (i < data.length - 1 && Math.abs(data[i].Normalized_Portfolio_Value - data[i + 1].Normalized_Portfolio_Value) <= 10)
        ) {
          filteredData.push(data[i]);
        }
      }
      return filteredData;
    };
  
    const filteredData = filterLargePriceDifferences(sortedData);

    // Extract normalized portfolio and index values
    const normalizedPortfolioValues = filteredData.map((dataPoint) => dataPoint.Normalized_Portfolio_Value);
    const normalizedIndexValues = filteredData.map((dataPoint) => dataPoint.Normalized_Index);

    // Generate the labels (dates) from the normalized data
    const labels = filteredData.map((dataPoint) => dataPoint.date.split('T')[0]);

    return {
        labels,
        datasets: [
            {
                label: 'Normalized Portfolio',
                data: normalizedPortfolioValues,
                borderColor: 'rgb(75, 192, 192)',
                borderDash: [5, 5],
                fill: false,
            },
            {
                label: 'Normalized Index',
                data: normalizedIndexValues,
                borderColor: 'rgb(153, 102, 255)',
                borderDash: [5, 5],
                fill: false,
            },
        ],
    };
}, [normalizedData]);

const barChartOptions = {
  chart: {
    type: 'bar',
    toolbar: {
      tools: {
        zoomin: true,
        zoomout: true,
        pan: true,
      },
    },
  },
  xaxis: {
    categories: geometricData.categories,
  },
  yaxis: {
    title: {
      text: 'Percentage',
    },
  },
  colors: ['#FF6384', '#36A2EB'], // Portfolio and Index colors
  tooltip: {
    shared: true,
    intersect: false,
  },
};
  // Placeholder bar chart data
  const barChartData = {
    labels: ['5Y', '3Y', '1Y', 'YTD'],
    datasets: [
      {
        label: 'Portfolio Returns',
        data: [20, 15, 10, 5],
        backgroundColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Index Returns',
        data: [18, 13, 9, 6],
        backgroundColor: 'rgb(153, 102, 255)',
      },
    ],
  };
  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  return (
<ThemeProvider theme={theme1}>
      <GlobalStyle />
      {/* <Container> */}
        
      <Container>
      {/* <Header 
              layoutModeType={theme === lightTheme ? 'light' : 'dark'}
              onChangeLayoutMode={handleLayoutModeChange}
              /> */}
        <Controls>
          <ControlRow>
            {/* <ControlGroup>
              <DropdownLabel htmlFor="portfolio">Portfolio:
              <select
                id="portfolio"
                value={globalPfId}
                onChange={(e) => setGlobalPfId(e.target.value)}
              >
                {globalPfIds.map((portfolioId, index) => (
                  <option key={index} value={portfolioId}>
                    Portfolio {portfolioId}
                  </option>
                ))}
              </select>

              <Label>
              ${realTimeValue !== null ? realTimeValue.toLocaleString() : 'Loading...'}
              </Label>
                        </DropdownLabel>
              
            </ControlGroup> */}
            

          </ControlRow>
        </Controls>        

        <ChartContainer>
          <ChartBox>
          <ControlGroup1>
              <label>
                <input type="checkbox" checked={isNormalized} onChange={() => setIsNormalized(!isNormalized)} />
                &nbsp; Normalize
              </label>
            </ControlGroup1>
            {/* <Title>Line Graph</Title> */}
            {!isNormalized ? 
                <>
                {lineChartData.labels.length > 0 ? (
                  <LineChartComponent finalLineData={finalLineData}/>
                ) : (
                  <p>Loading line chart...</p>
                )}
                </>:
                <>
                {normalizedLineChartData.labels.length > 0 ? (
                  // <Line data={normalizedLineChartData} />
                  <NormalizedLineChartComponent finalLineData={normalizedData}/>
                ) : (
                  <p>Loading line chart...</p>
                )}
                </> 
            }
          </ChartBox>
          <ChartBox>
            {/* <Title>Bar Chart</Title> */}
            {/* <Bar data={barChartData} /> */}
            {/* <BarChartComponent returns={geometricData} indexName={indexName}/> */}
            {geometricData?.series && 
            <Chart
        options={barChartOptions}
        series={geometricData?.series}
        type="bar"
        height={350}
      />}
          </ChartBox>
        </ChartContainer>
      </Container>
    </ThemeProvider>
  );
};

export default PortfolioNew;