import React, { useState } from 'react';
import styled from 'styled-components';

const DateRangeSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  width: 100%;
`;

const DateRangeButton = styled.button`
  padding: 5px 5px;
  margin-right: 5px;
  background-color: ${(props) => (props.active ? '#1abc9c' : '#ecf0f1')};
  color: ${(props) => (props.active ? '#fff' : '#34495e')};
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.active ? '#16a085' : '#bdc3c7')};
  }
`;

const DateInput = styled.input`
  padding: 10px;
  width: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 8px;
  margin-left: 15px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #1abc9c;
  }
`;

const CustomDateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const DateRangeSelector = ({ onDateRangeChange, onCustomDateChange }) => {
  const [activeRange, setActiveRange] = useState('1D');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleRangeClick = (range) => {
    setActiveRange(range);
    let from_date, to_date;
    const today = new Date();

    if (range === "1D") {
      // Use intraday API for 1D timeframe
      let adjustedDate = new Date(today);
      if (today.getDay() === 6) { // Saturday
        adjustedDate.setDate(today.getDate() - 1);
      } else if (today.getDay() === 0) { // Sunday
        adjustedDate.setDate(today.getDate() - 2);
      }
      from_date = adjustedDate.toISOString().split("T")[0];
      to_date = from_date;  // For 1D, the start and end date are the same
    } else if (range === "custom") {
      if (!startDate || !endDate) {
        alert("Start date and end date are required for 'custom' timeframe");
        return;
      }
      from_date = startDate;
      to_date = endDate;
    } else {
      // Use historical price API for other timeframes
      switch (range) {
        case "YTD":
          from_date = `${today.getFullYear()}-01-01`;
          break;
        case "5D":
          from_date = new Date(today.getTime() - 5 * 24 * 60 * 60 * 1000).toISOString().split("T")[0];
          break;
        case "1M":
          from_date = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split("T")[0];
          break;
        case "3M":
          from_date = new Date(today.getTime() - 3 * 30 * 24 * 60 * 60 * 1000).toISOString().split("T")[0];
          break;
        case "6M":
          from_date = new Date(today.getTime() - 6 * 30 * 24 * 60 * 60 * 1000).toISOString().split("T")[0];
          break;
        case "1Y":
          from_date = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000).toISOString().split("T")[0];
          break;
        case "3Y":
          from_date = new Date(today.getTime() - 3 * 365 * 24 * 60 * 60 * 1000).toISOString().split("T")[0];
          break;
        case "5Y":
          from_date = new Date(today.getTime() - 5 * 365 * 24 * 60 * 60 * 1000).toISOString().split("T")[0];
          break;
        case "10Y":
          from_date = new Date(today.getTime() - 10 * 365 * 24 * 60 * 60 * 1000).toISOString().split("T")[0];
          break;
        default:
          throw new Error("Invalid timeframe");
      }
      to_date = today.toISOString().split("T")[0];
    }

    onDateRangeChange(from_date, range);
  };

  const handleCustomDateChange = () => {
    if (!startDate || !endDate) {
      alert("Start date and end date are required for 'custom' timeframe");
      return;
    }
    onCustomDateChange(startDate, endDate);
  };

  return (
    <DateRangeSelectorContainer>
      <div>
        {['1D', '1M', '3M', '6M', '1Y', 'YTD', '5Y'].map((range) => (
          <DateRangeButton
            key={range}
            active={activeRange === range}
            onClick={() => handleRangeClick(range)}
          >
            {range}
          </DateRangeButton>
        ))}
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DateRangeButton
          active={activeRange === 'custom'}
          onClick={() => setActiveRange('custom')}
        >
          Custom
        </DateRangeButton>

        {activeRange === 'custom' && (
          <CustomDateRangeContainer>
            <DateInput
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />
            <DateInput
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
            />
            <DateRangeButton onClick={handleCustomDateChange}>Apply</DateRangeButton>
          </CustomDateRangeContainer>
        )}
      </div>
    </DateRangeSelectorContainer>
  );
};

export default DateRangeSelector;
