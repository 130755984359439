// src/supabaseClient.js
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://sxmdrvgxbxboqxkxzoqq.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN4bWRydmd4Ynhib3F4a3h6b3FxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQ2NjczODgsImV4cCI6MjAyMDI0MzM4OH0.452PsWnRwhrMeUkj5L8iCPzdM_Fi-v6cse-oVgqaons';
// const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN4bWRydmd4Ynhib3F4a3h6b3FxIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcwNDY2NzM4OCwiZXhwIjoyMDIwMjQzMzg4fQ.HyCqh41MPR5uvX7Lr2XEyjl_qyXAnkeTLelPNnzGGp4';

export const supabase = createClient(supabaseUrl, supabaseKey);


        